// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xLdyFHzE6CuHlEdvImZO{\n\n}\n\n.FxrihR0xL5hGV8RWkwDF{\n\n}\n\n._INYMlkt9xsLfAOTHrKM{\n\n}\n.qNjCoveYSMjtKLaLpPA5{\n\n}\n.zQPx73Hi4hec9DTaKl5m{\n\n}\n\n.mBly0YRXb8neUzYn870K{\n\n}\n\n.Wotz_v5eZcg5V4UQO40V{\n\n}\n\n.qKQ2XlzsQzoEmIbTkdZI{\n\n}\n\n.GcgeHt3YqTfMLvuvXuPX{\n\n}\n.Ngp5fbkAxcYejEo_p_my{\n\n}\n\n.lJ7YWV3tmFvh5qkZpXtj{\n\n}\n\n.BPs_IQYlfypdpV0X0839{\n    fill: \"blue\";\n}\n\n.CRA8UxoKAioNlK_l6rnY{\n\n}\n\n.HKoWVr4diw20sU64SXLs{\n\n}\n\n\n.dcPxMl_5otj6lpmR96R8{\n\n}\n\n.K8bV65hiYdhs1W5naTWF{\n\n}\n\n.QdXxdU1fkTlMDBfOM1yU{\n\n}\n\n.TTez5TtDuELmXdyK7tvi{\n\n}\n\n.EMXEOnGoWbd7fc7Hoyv6{\n\n}\n\n.XpYs62Wa9pOYWQVyozz1{\n\n}\n\n.hPB3zLPUfgWfypCRZG8N{\n\n}\n\n.BneLNGShnfXx1PCjL3cN{\n\n}\n\n.cZZuYKV9buTIUEul9jia{\n\n}\n\n.W6SBo6pJ2ycQAl_3XAO2{\n\n}\n\n.TFvHKPaReb9_VE5dNlvS{\n    \n}\n\n.fTG87SrpZ7kY5C_eNqvu{\n\n}", "",{"version":3,"sources":["webpack://./src/Components/Charts/WorldMap/Countries.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;AACA;;AAEA;AACA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;AACA;;AAEA;;AAEA;;AAEA;;AAEA;IACI,YAAY;AAChB;;AAEA;;AAEA;;AAEA;;AAEA;;;AAGA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA;;AAEA","sourcesContent":[".Angola{\n\n}\n\n.Argentina{\n\n}\n\n.Australia{\n\n}\n.Azerbaijan{\n\n}\n.AmericaSamoa{\n\n}\n\n.AntiguaandBarbuda{\n\n}\n\n.Bahamas{\n\n}\n\n.Comoros{\n\n}\n\n.Canada{\n\n}\n.China{\n\n}\n\n.Chile{\n\n}\n\n.Denmark{\n    fill: \"blue\";\n}\n\n.France{\n\n}\n\n.Greece{\n\n}\n\n\n.Italy{\n\n}\n\n.Japan{\n\n}\n\n.Malaysia{\n\n}\n\n.Norway{\n\n}\n\n.NewZealand{\n\n}\n\n.UK{\n\n}\n\n.USA{\n\n}\n\n.Oman{\n\n}\n\n.Philippines{\n\n}\n\n.PapuaNewGuinea{\n\n}\n\n.Russia{\n    \n}\n\n.Turkey{\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Angola": "xLdyFHzE6CuHlEdvImZO",
	"Argentina": "FxrihR0xL5hGV8RWkwDF",
	"Australia": "_INYMlkt9xsLfAOTHrKM",
	"Azerbaijan": "qNjCoveYSMjtKLaLpPA5",
	"AmericaSamoa": "zQPx73Hi4hec9DTaKl5m",
	"AntiguaandBarbuda": "mBly0YRXb8neUzYn870K",
	"Bahamas": "Wotz_v5eZcg5V4UQO40V",
	"Comoros": "qKQ2XlzsQzoEmIbTkdZI",
	"Canada": "GcgeHt3YqTfMLvuvXuPX",
	"China": "Ngp5fbkAxcYejEo_p_my",
	"Chile": "lJ7YWV3tmFvh5qkZpXtj",
	"Denmark": "BPs_IQYlfypdpV0X0839",
	"France": "CRA8UxoKAioNlK_l6rnY",
	"Greece": "HKoWVr4diw20sU64SXLs",
	"Italy": "dcPxMl_5otj6lpmR96R8",
	"Japan": "K8bV65hiYdhs1W5naTWF",
	"Malaysia": "QdXxdU1fkTlMDBfOM1yU",
	"Norway": "TTez5TtDuELmXdyK7tvi",
	"NewZealand": "EMXEOnGoWbd7fc7Hoyv6",
	"UK": "XpYs62Wa9pOYWQVyozz1",
	"USA": "hPB3zLPUfgWfypCRZG8N",
	"Oman": "BneLNGShnfXx1PCjL3cN",
	"Philippines": "cZZuYKV9buTIUEul9jia",
	"PapuaNewGuinea": "W6SBo6pJ2ycQAl_3XAO2",
	"Russia": "TFvHKPaReb9_VE5dNlvS",
	"Turkey": "fTG87SrpZ7kY5C_eNqvu"
};
export default ___CSS_LOADER_EXPORT___;
